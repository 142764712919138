import React from "react"
import { Link } from  "gatsby"
import  "../styles/global.scss"
import { Container, Col, Row } from 'reactstrap';
import Layout from "../layouts/index"
import EventCard from "../components/EventCard"
import { withPrefix } from "gatsby"
import { IoIosAnalytics } from "react-icons/io";
import { Helmet } from 'react-helmet';

const EventPage  = () => (
	<Layout>
	<Helmet>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
          <title>Interaid Pharmacy | Events</title>
          <meta name="title" content="Interaid Pharmacy | Events" />
          <meta name="description" content="Stay updated with Interaid Pharmacy’s upcoming events. Find out what’s happening in Interaid Pharmacy recently." />
          <link rel="canonical" href="www.interaidpharmacy.com.my/event" />

        </Helmet>

		<Container fluid="true" className="page-title-block event-page-title-block">
	        <Row>
	  			<span className="text-center font-dark text-bold-500 text-white">Event</span>
	 		</Row>
	    </Container>
		<Container fluid="true" className="page-block text-center">
		
			<IoIosAnalytics size="2em" color="#6d886f"/>
	     	<br/><br/>
			<p className="page-summary font-dark text-bold-500">Stay updated with Interaid Pharmacy’s upcoming events. Find out what’s happening in Interaid Pharmacy recently.</p>
			<hr className="title-divider"/>
	     	<br/>
			
			<Row>
				


			</Row>

		</Container>
	</Layout>
 )

export default EventPage
// To add event, append the below code after line 36 <Row>, putting this here as we cant place comment in gatsby code
// <Col md="4" >
	// <Link to="events/blood-test-event"><EventCard img={withPrefix('/assets/img/blood-test-1.png')} title="blood test" description="hihi this is desc hihi this is desc hihi this is desc " date="24" monthyear="JAN, 2019" day="Wednesday" time="10am-2pm"/></Link>
// </Col>

